@mixin icon($icon-url) {
  width: 45px;
  height: 45px;
  display: inline-block;
  background-size: contain;
  mask: url($icon-url) no-repeat center center;
  -webkit-mask: url($icon-url) no-repeat center center;
  vertical-align: middle;
}

.bonus-icon {
  @include icon('/assets/icons/missions.svg');
}

.back-icon {
  @include icon('/assets/icons/arrow-back.svg');
}

.close-icon {
  @include icon('/assets/icons/cross.svg');
}

.challenge-icon {
  @include icon('/assets/icons/challenge.svg');
}

.ranking-icon {
  @include icon('/assets/icons/classification.svg');
}

.news-feed-icon {
  @include icon('/assets/icons/news-feed.svg');
}

.message-icon {
  @include icon('/assets/icons/messages.svg');
}

.new-team-image {
  @include icon('/assets/icons/add-team.svg');
  height: 100%;
  width: 100%;
}

.arrived-destination-message .image-container {
  background-image: url('../icons/marker.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  height: 70vh;
  display: block;
}

.freddy-audio {
  background-image: url('../img/micro.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50vh;
}
