@mixin image($image-url) {
  display: inline-block;
  background-image: url($image-url);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mask: none;
  height: 100px;
  width: 100px;
}

.next-mission-image {
  @include image('/assets/icons/marker.svg');
  scale: 1.3;
}

.end-game .image-container,
.missions-completed .image-container {
  background-image: url('../../assets/icons/home-pin.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  display: block;
  height: 500px;
}
