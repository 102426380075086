/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';

@import 'bootstrap-icons/font/bootstrap-icons.css';

$font-size-base: 1.25rem; // Assumes the browser default, typically 16px

@import 'assets/scss/global';

//@import 'assets/theme/freddy/styles/style';
