// create new class to use in html

$utilities: (
  'opacity': (
    responsive: true,
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
  'justify-content': (
    responsive: true,
    property: justify-content,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      evenly: space-evenly,
    ),
  ),
);

.transition-container {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  overflow: hidden;
}
