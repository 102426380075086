// button

$btn-border-radius: 80px;
$btn-border-radius-lg: 80px;

// color palette

$primary: #1ee781;
$secondary: #0fcee7;
$danger: #ff9878;
$success: #02f99d;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$team-me-up-theme-colors: (
  'alt-primary': #1ee781,
);

$theme-colors: map-merge($team-me-up-theme-colors, $theme-colors);

.modal-body {
  --bs-modal-bg: url('/assets/brand/bg.jpg') repeat center;
}
