@import 'bootstrap/scss/variables';

/* - - - - custom code, animations - - - - */
.modal-backdrop {
  z-index: 10;
}

.mission-completed .card-title {
  text-decoration: line-through;
}

html {
  scroll-behavior: smooth;
}

.ion-page-invisible {
  opacity: 1 !important;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-top-right {
  top: 50% !important;
}

.mapboxgl-user-location-dot {
  background-color: transparent !important;
}
.mapboxgl-user-location-dot::before,
.mapboxgl-user-location-dot::after {
  display: none !important;
}

.mapboxgl-canvas {
  height: 100vh !important;
  width: 100vw !important;
}

.vjs-record .vjs-device-button.vjs-control {
  display: none !important;
}

// Ensure svg icons using the text-light-color
.text-light {
  i {
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-text-opacity)
    ) !important;
  }
}

// Color the svg icon
i.text-dark {
  background-color: rgba(var(--bs-dark-rgb)) !important;
}

.bg-dark {
  .btn-close {
    background-color: white;
  }
}

// teams marker
.custom-team circle {
  fill: #fff;
  opacity: 0.2;
}

.custom-team ellipse {
  fill: #000;
  opacity: 0.05;
}

// Modal
.modal-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 1rem;
  justify-items: center;

  button {
    background: transparent;
  }
}

.btn-close {
  background: url('/assets/icons/cross.svg') no-repeat center !important;
  opacity: 1 !important;
}

.btn-back {
  background: url('/assets/icons/arrow-back.svg') no-repeat center !important;
  opacity: 1 !important;
  width: 1rem;
  height: 1rem;
  box-sizing: content-box;
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.form-range {
  margin-top: 1.5rem;
}

/*
Shepperd colors
*/

.shepherd-button {
  background: var(--primary) !important;
}

.shepherd-element[data-popper-placement='top'] > .shepherd-arrow:before {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.shepherd-element[data-popper-placement='bottom'] > .shepherd-arrow:before {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
